import Airwallex, {
  init as initFn,
  redirectToCheckout as redirectToCheckoutFn,
  createElement as createElementFn,
  destroyElement as destroyElementFn,
  getElement as getElementFn,
  confirmPaymentIntent as confirmPaymentIntentFn,
  confirmPaymentIntentWithSavedCard as confirmPaymentIntentWithSavedCardFn,
  createPaymentMethod as createPaymentMethodFn,
  getPaymentIntent as getPaymentIntentFn,
  createPaymentConsent as createPaymentConsentFn,
  AirwallexEnv,
} from '../types/airwallex';
import { getDeviceFingerprint as getDeviceFingerprintFn } from '../types/fraud';
import { loadAirwallex as loadAirwallexFn } from '../types/index';
import { version } from '../package.json';

const ENV_HOST = {
  prod: 'checkout.airwallex.com',
  demo: 'checkout-demo.airwallex.com',
  staging: 'checkout-staging.airwallex.com',
  /**
   * Below env only for the npm package development
   * Should not using them when integrate with Airwallex
   */
  dev: 'checkout-dev.airwallex.com',
  local: 'localhost:3000',
};

declare global {
  interface Window {
    Airwallex: Airwallex;
    ReactNativeWebView: {
      postMessage: (message: string) => void;
    };
  }
}

/**
 * @example
 * ```ts 
import { gatewayUrl } from 'airwallex-payment-elements';
const gatewayUrl = getGatewayUrl('prod');
```
 */

export const getGatewayUrl = (env: AirwallexEnv): string => `https://${ENV_HOST[env] || ENV_HOST.prod}`;

const STATIC_JS_URL = `/assets/elements.bundle.min.js?version=${version}`;

/**
 * @example
 * ```ts 
import { loadAirwallexJs } from 'airwallex-payment-elements';
const gatewayUrl = getGatewayUrl('prod');
loadAirwallexJs(gatewayUrl);
```
 */

export const loadAirwallexJs = (gatewayUrl: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.src = `${gatewayUrl}${STATIC_JS_URL}`;
  script.crossOrigin = 'anonymous';

  const headOrBody = document.head || document.body;

  if (!headOrBody) {
    throw new Error('Airwallex payment scripts requires a <head> or <body> html element in order to be loaded.');
  }

  headOrBody.appendChild(script);

  return script;
};

/**
 * @example
 * ```ts 
import { loadAirwallex } from 'airwallex-payment-elements';
loadAirwallex({
  env: 'prod',
  locale: 'en',
});
```
 */

export const loadAirwallex: typeof loadAirwallexFn = async (options) => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (window.Airwallex) {
    return window.Airwallex;
  }

  const MAX_RETRY_COUNT = 3;
  let RETRY_COUNT = 0;
  const sleep = () => new Promise((resolve) => window.setTimeout(resolve, 500));

  const tryToResolve = async (): Promise<Airwallex> => {
    const script: HTMLScriptElement =
      document.querySelector(`script[src="${STATIC_JS_URL}"], script[src="${STATIC_JS_URL}/"]`) ||
      loadAirwallexJs(getGatewayUrl(options?.env || 'prod'));

    return new Promise((resolve, reject) => {
      script.addEventListener('load', () => {
        if (window.Airwallex) {
          window.Airwallex.init(options);
          resolve(window.Airwallex);
        } else {
          reject(new Error('Failed to load Airwallex on load event'));
        }
      });

      script.addEventListener('error', () => {
        reject(new Error('Failed to load Airwallex scripts'));
        script.remove && script.remove();
      });
    });
  };

  while (RETRY_COUNT < MAX_RETRY_COUNT) {
    try {
      return await tryToResolve();
    } catch (error) {
      RETRY_COUNT++;
      await sleep();
    }
  }

  return null;
};

/**
 * @example
 * ```ts 
import { init } from 'airwallex-payment-elements';
init({
  env: 'prod',
  locale: 'en',
});
```
 */

export const init: typeof initFn = (options) => {
  if (!window.Airwallex) {
    console.error('Please loadAirwallex() before init();');
  } else {
    window.Airwallex.init(options);
  }
};

/**
 * @example
 * ```ts 
import { redirectToCheckout } from 'airwallex-payment-elements';
redirectToCheckout({
  env: 'prod',
  intent_id: 'replace-with-your-intent_id',
  client_secret: 'replace-with-your-client-secret',
  successUrl: `${window.location.origin}/checkout-success?isTesting=N`,
  locale: 'replace-with-your-language',
  showTermLink: true,
  theme: {
    variant: 'bootstrap',
    fonts: [
      {
        src: 'https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXSub-BoldSubset.woff2',
        family: 'AxLLCircular',
        weight: '700',
      },
      {
        src: 'https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2',
        family: 'AxLLCircular',
        weight: '400',
      },
    ],
    popupWidth: 418,
    popupHeight: 549,
  },
  applePayRequestOptions: {
    countryCode: 'US',
  },
  googlePayRequestOptions: {
    countryCode: 'US',
  },
  requiredBillingContactFields: ['email', 'name'],
  currency: 'HKD',
});
```
 */

export const redirectToCheckout: typeof redirectToCheckoutFn = (props) => {
  if (!window.Airwallex) {
    console.error('Please loadAirwallex() before redirectToCheckout();');
  } else {
    return window.Airwallex.redirectToCheckout(props);
  }
};

/**
 * We encourage to use `dropIn` element, since it's easy to scale new payment methods
 * We suggest to NOT use `applePayButton`, `googlePayButton`, `wechat`, `qrcode`, `redirect`, `fullFeaturedCard` and `directDebit` elements
 * 
 * @example
 * ```ts 
import { createElement } from 'airwallex-payment-elements';
const element = createElement('dropIn', {
  intent_id: 'replace-with-your-intent_id',
  client_secret: 'replace-with-your-client-secret',
  currency: 'replace-with-your-intent-currency',
  // if you want to use apple pay, please pass merchant country code in applePayRequestOptions
  applePayRequestOptions: {
    countryCode: 'replace-with-your-country-code',
  },
  // if you want to use google pay, please pass merchant country code in googlePayRequestOptions
  googlePayRequestOptions: {
    countryCode: 'replace-with-your-country-code',
  },
});
```
 */

export const createElement: typeof createElementFn = (type, options) => {
  if (!window.Airwallex) {
    console.error('Please loadAirwallex() before createElement();');
    return null;
  } else {
    return window.Airwallex.createElement(type, options);
  }
};

/**
 * @example
 * ```ts 
import { destroyElement } from 'airwallex-payment-elements';
destroyElement('dropIn');
```
 */

export const destroyElement: typeof destroyElementFn = (type) => {
  if (!window.Airwallex) {
    console.error('Please loadAirwallex() before destroyElement();');
    return false;
  } else {
    return window.Airwallex.destroyElement(type);
  }
};

/**
 * @example
 * ```ts 
import { getElement } from 'airwallex-payment-elements';
getElement('dropIn');
```
 */

export const getElement: typeof getElementFn = (type) => {
  if (!window.Airwallex) {
    console.error('Please loadAirwallex() before getElement();');
    return null;
  } else {
    return window.Airwallex.getElement(type);
  }
};

/**
 * @example
 * ```ts 
import { confirmPaymentIntent } from 'airwallex-payment-elements';
confirmPaymentIntent({
  element: card, // Provide Card element
  intent_id: 'replace-with-your-intent_id', // Payment Intent ID
  client_secret: 'replace-with-your-client-secret', // Client Secret
  payment_method_options: {
    card: {
      auto_capture: true,
    },
  },
})
```
 */

export const confirmPaymentIntent: typeof confirmPaymentIntentFn = async (data) => {
  if (!window.Airwallex) {
    const err = 'Please loadAirwallex() before confirmPaymentIntent();';
    console.error(err);
    throw new Error(err);
  } else {
    return window.Airwallex.confirmPaymentIntent(data);
  }
};

/**
 * @example
 * ```ts 
import { confirmPaymentIntentWithSavedCard } from 'airwallex-payment-elements';
confirmPaymentIntentWithSavedCard({
  element: card, // Provide Card element
  customer_id: 'replace-with-your-customer-id',
  methodId: 'replace-with-your-method-id',
  intent_id: 'replace-with-your-intent_id', // Payment Intent ID
  client_secret: 'replace-with-your-client-secret', // Client Secret
})
```
 */

export const confirmPaymentIntentWithSavedCard: typeof confirmPaymentIntentWithSavedCardFn = async (data) => {
  if (!window.Airwallex) {
    const err = 'Please loadAirwallex() before confirmPaymentIntentWithSavedCard();';
    console.error(err);
    throw new Error(err);
  } else {
    return window.Airwallex.confirmPaymentIntentWithSavedCard(data);
  }
};

/**
 * @example
 * ```ts 
import { createPaymentMethod } from 'airwallex-payment-elements';
createPaymentMethod('replace-with-your-client-secret', {
  element: card, // Provide Card element
  customer_id: 'replace-with-your-customer-id',
})
```
 */

export const createPaymentMethod: typeof createPaymentMethodFn = async (client_secret, data) => {
  if (!window.Airwallex) {
    const err = 'Please loadAirwallex() before createPaymentMethod();';
    console.error(err);
    throw new Error(err);
  } else {
    return window.Airwallex.createPaymentMethod(client_secret, data);
  }
};

/**
 * @example
 * ```ts 
import { getPaymentIntent } from 'airwallex-payment-elements';
getPaymentIntent('replace-with-your-intent_id',  'replace-with-your-client_secret')
```
 */

export const getPaymentIntent: typeof getPaymentIntentFn = async (id, client_secret) => {
  if (!window.Airwallex) {
    const err = 'Please loadAirwallex() before getPaymentIntent();';
    console.error(err);
    throw new Error(err);
  } else {
    return window.Airwallex.getPaymentIntent(id, client_secret);
  }
};

/**
 * @example
 * ```ts 
import { createPaymentConsent } from 'airwallex-payment-elements';
createPaymentConsent({
  intent_id: 'replace-with-your-intent_id',
  customer_id: 'replace-with-your-customer_id',
  cardname: 'replace-with-your-card_name',
  client_secret: 'replace-with-your-client_secret',
  currency: 'HKD,
  element: cardNumberElement,
  next_triggered_by: 'customer',
  billing: {
    email: 'test@airwallex.com',
    first_name: 'TEST',
    last_name: 'TEST',
    date_of_birth: '2020-07-05',
    phone_number: '13333333333',
    address: {
      city: 'shanghai',
      country_code: 'CN',
      postcode: '200000',
      state: 'Shanghai',
      street: 'Street',
    },
  },
})
```
 */

export const createPaymentConsent: typeof createPaymentConsentFn = async (data) => {
  if (!window.Airwallex) {
    const err = 'Please loadAirwallex() before createPaymentConsent();';
    console.error(err);
    throw new Error(err);
  } else {
    return window.Airwallex.createPaymentConsent(data);
  }
};

/**
 * @example
 * ```ts 
import { getDeviceFingerprint } from 'airwallex-payment-elements';
getDeviceFingerprint({
  intent_id: 'replace-with-your-intent_id',
  env: 'prod',
})
```
 */

export const getDeviceFingerprint: typeof getDeviceFingerprintFn = (data) => {
  return window.Airwallex.getDeviceFingerprint(data);
};

export default {
  getGatewayUrl,
  loadAirwallex,
  loadAirwallexJs,
  init,
  redirectToCheckout,
  createElement,
  destroyElement,
  getDeviceFingerprint,
  getElement,
  getPaymentIntent,
  createPaymentMethod,
  createPaymentConsent,
  confirmPaymentIntent,
  confirmPaymentIntentWithSavedCard,
};
